"use client";

import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

const Popup = ({ title, open, setOpen, children, sx }: {
  children: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  sx?: any
}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, setOpen]);

  return (
    <div
      className={`modal-container ${open ? "open" : ""}`}
      role="dialog"
      tabIndex={open ? 0 : -1}
      onClick={() => setOpen(false)} 
      style={{ ...sx }}
    >
      <div
        ref={popupRef}
        className={`modal-content ${open ? "open" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>


  );
};

export default Popup;