"use client";
import { memo } from "react";
import { Typography } from "@mui/material";
import Link from "next/link";
import Image from "next/image";
import useMediaQuery from "@mui/material/useMediaQuery";

const HeroSection = ({
  bgUrl,
  imgAlt,
  title,
  btnUrl,
  btnText,
  subTitle,
  funfactList,
  mobileBgUrl,
}) => {
  const isLg = useMediaQuery("(min-width: 991px)");

  return (
    <section
      id="hero"
      className="cs-hero cs-style-2 cs-bg-filed"
      role="banner"
      aria-label={imgAlt}>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <Image
          src={isLg ? bgUrl : mobileBgUrl}
          alt={imgAlt || "Background Image"}
          className="hero-fc-img"
          width={1600}
          height={1030}
          loading="eager"
        />
        <div className="container">
          <div className="cs-hero-text">
            <Typography
              variant="h1"
              sx={{
                lineHeight: "1.12em",
                fontSize: {
                  lg: "68px",
                  md: "68px",
                  sm: "42px",
                  xs: "32px",
                },
                fontWeight: "revert",
                fontFamily: "var(--font-primary)",
                mb: {
                  lg: "83px",
                  md: "83px",
                  sm: "83px",
                  xs: "20px",
                },
                maxWidth: "900px",
              }}>
              {title}
            </Typography>
            <div className="cs-hero-text-in">
              <div className="cs-hero-btn">
                <Link
                  href={btnUrl}
                  className="cs-text-btn"
                  style={{ color: "#033449", fontWeight: 600 }}>
                  {btnText}
                </Link>
              </div>
              <p className="cs-hero-subtitle">{subTitle}</p>
            </div>
          </div>
        </div>
        <div className="container numbers-section">
          <div className="cs-hero-bottom">
            <div className="cs-hero-bottom-right">
              {funfactList?.length > 0 && (
                <div className="cs-hero-funfact text-center">
                  {funfactList?.map((item, index) => (
                    <div
                      className="cs-hero-funfact-col"
                      key={index}
                      style={{ color: "#033449" }}>
                      <h2
                        className="cs-white-color cs-fs-72"
                        style={{ color: "inherit" }}>
                        {item.number}
                      </h2>
                      <p
                        className="cs-white-color mb-0"
                        style={{ color: "inherit" }}>
                        {item.title}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// Memoize the HeroSection component
export default memo(HeroSection);
