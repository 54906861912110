import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Box, Grid } from "@mui/material";
import TestimonialStyle3 from "../Testimonial/TestimonialStyle3";
import Image from "next/image";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function TestimonialCarouselStyle2({ data }) {
  const [width, setWidth] = useState(0);
  const [translate, setTranslate] = useState(0);
  const itemRef = useRef(null); // Ref for the Grid item
  const sliderRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  // Media query for responsive breakpoints
  const isSm = useMediaQuery("(min-width: 768px) and (max-width: 899px)");
  const isXs = useMediaQuery("(max-width: 575px)");

  // Calculate the gap based on screen size, optimized by memoization
  const gap = useMemo(() => (isSm || isXs ? 0 : 1.5), [isSm, isXs]);

  // Calculate maximum translation based on the number of items and width
  const maxTranslate = useMemo(
    () => -(data?.length - 1) * (width + gap),
    [data?.length, width, gap]
  );

  // Set the width of the item based on the itemRef element
  useEffect(() => {
    if (itemRef.current) {
      setWidth(itemRef.current.offsetWidth);
    }
  }, []); // No dependencies as we only need to calculate width on mount

  // Auto-scroll function with memoized callback to prevent unnecessary re-renders
  const startAutoScroll = useCallback(() => {
    scrollIntervalRef.current = setInterval(() => {
      requestAnimationFrame(() => {
        setTranslate((prevTranslate) =>
          prevTranslate <= maxTranslate
            ? 0
            : prevTranslate - width - 16 * gap
        );
      });
    }, 10000); // Adjusted scroll interval to 10s
  }, [maxTranslate, width, gap]);

  // Clear auto-scroll interval
  const clearAutoScroll = useCallback(() => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }
  }, []);

  // Move to the next item
  const next = useCallback(() => {
    setTranslate((prevTranslate) =>
      prevTranslate <= maxTranslate ? 0 : prevTranslate - width - 16 * gap
    );
    clearAutoScroll();
    startAutoScroll();
  }, [clearAutoScroll, maxTranslate, startAutoScroll, width, gap]);

  // Move to the previous item
  const previous = useCallback(() => {
    setTranslate((prevTranslate) =>
      prevTranslate === 0 ? maxTranslate : prevTranslate + width + 16 * gap
    );
    clearAutoScroll();
    startAutoScroll();
  }, [clearAutoScroll, maxTranslate, startAutoScroll, width, gap]);

  // Start and clear the auto-scroll on component mount/unmount
  useEffect(() => {
    startAutoScroll();
    return () => clearAutoScroll(); // Cleanup on unmount
  }, [startAutoScroll, clearAutoScroll]);



  return (
    <>
      <Box
        sx={{
          width: {
            xs: "90vw",
            md: `90vw`,
          },
          margin: "0 auto",
          marginTop: "-4rem",
          position: "relative",
          overflow: "hidden",
          height: "100%",
        }}>
        <Grid
          continer
          ref={sliderRef}
          className="cs-slider-activate"
          sx={{
            userSelect: "text",
            transform: `translateX(${translate}px)`,
            transition: "0.3s ease-in-out",
            display: "flex",
            flexWrap: "nowrap",
          }}>
          {data?.map((item, index) => (
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              key={index}
              ref={itemRef}
              sx={{
                minWidth: {
                  xs: `calc(90vw)`,
                  md: `calc(45vw - ${gap}rem)`,
                },
                marginX: {
                  xs: 0,
                  md: `${gap / 2}rem`,
                },
              }}>
              <TestimonialStyle3 {...item} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <div
        className="cs-slider-navigation cs-style-2"
        style={{
          justifyContent: "center",
          userSelect: "none",
        }}>
        <Image
          src="/images/icons/left_arrow_blue.svg"
          alt="Previous"
          height={14}
          width={20}
          loading="lazy"
          priority={false}
          onClick={previous}
          tabIndex={0}
          role="button"
          aria-label="Previous testimonial"
          style={{
            cursor: "pointer",
          }}
        />
        <Image
          src="/images/icons/right_arrow_blue.svg"
          alt="Next"
          height={14}
          width={20}
          loading="lazy"
          priority={false}
          onClick={next}
          tabIndex={0}
          role="button"
          aria-label="Next testimonial"
          style={{
            cursor: "pointer",
          }}
        />
      </div>
    </>
  );
}
